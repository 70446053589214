import React, {useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword} from 'firebase/auth'
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, child as child_storage } from 'firebase/storage'
import { getDatabase, ref as ref_realtime, set, get, child } from 'firebase/database'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { notification } from 'antd'
import ReactPlayer from "react-player"


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
SwiperCore.use([Autoplay]);


export const JRNView = () => {

  const firebaseConfig = {
    apiKey: "AIzaSyAWQejHdvHIU9w2bfvtnCRdShCfxEZY808",
    authDomain: "james-r-norman.firebaseapp.com",
    databaseURL: "https://james-r-norman-default-rtdb.firebaseio.com",
    projectId: "james-r-norman",
    storageBucket: "james-r-norman.appspot.com",
    messagingSenderId: "293494062400",
    appId: "1:293494062400:web:f0d1e46969ce5387cbcea5",
    measurementId: "G-SE0GXY8GX7"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth()
  const storage = getStorage()
  const database = getDatabase()

  const [signedIn, setSignedIn] = useState(() => false)
  const [authModalOpen, setAuthModalOpen] = useState(() => false)
  const [authEmail, setAuthEmail] = useState(() => "")
  const [authPwd, setAuthPwd] = useState(() => "")
  const [user, setUser] = useState(() => null)
  const [carouselImageUpload, setCarouselImageUpload] = useState(() => null)
  const [carouselAdditionName, setCarouselAdditionName] = useState(() => "")
  const [isEditCarouselOpen, setIsEditCarouselOpen] = useState(() => false)
  const [carouselImages, setCarouselImages] = useState(() => [])
  const [placementModalOpen, setPlacementmodalOpen] = useState(() => false)
  const [movieName, setMovieName] = useState(() => "")
  const [moviePoster, setMoviePoster] = useState(() => null)
  const [trailerLink, setTrailerLink] = useState(() => "")
  const [startTime, setStartTime] = useState(() => "")
  const [endTime, setEndTime] = useState(() => "")
  const [songName, setSongName] = useState(() => "")
  const [posterImages, setPosterImages] = useState(() => [])
  const [posterInfo, setPosterInfo] = useState(() => [])
  const [isTrailerModalOpen, setIsTrailerModalOpen] = useState(() => false)
  const [currentModalInfo, setCurrentModalInfo] = useState(() => null)

  useEffect(() => {
    let storageRef = ref(storage, '/carousel_images')
    const fetchImages = async () => {
    let result = await listAll(storageRef);
      let urlPromises = result.items.map(imageRef => getDownloadURL(ref(storage, imageRef)));
      return Promise.all(urlPromises);
    }
    const loadImages = async () => {
      const urls = await fetchImages();
      setCarouselImages(urls);
    }
    loadImages();
  }, []);

  useEffect(() => {
    const dbRef = ref_realtime(database)
    get(child(dbRef, 'posters/')).then((snapshot) => {
      let posterInfoTemp = []
      const data = snapshot.val()
      for (let item in data){
        get(child(dbRef, 'posters/' + item)).then(movieSnapshot => {
          const placementData = movieSnapshot.val()
          const song = placementData['song']
          const link = placementData['link']
          const start = placementData['start']
          const end = placementData['end']
          const id = placementData['id']
          const img_name = item.replace(/ /g, '%20').replace(':', '%3A')
          posterInfoTemp.push({name: item, song: song, link: link, start: start, end: end, id: id})
        })
      }
      setPosterInfo(posterInfoTemp)
    })
  }, []);

  useEffect(() => {
    const dbRef = ref_realtime(database)
    const storageRef = ref(storage, '/posters/')
    const fetchImages = async () => {
    let result = await listAll(storageRef);
      let urlPromises = result.items.map(imageRef => getDownloadURL(ref(storage, imageRef)));
      return Promise.all(urlPromises);
    }
    const loadImages = async () => {
      const urls = await fetchImages();
      setPosterImages(urls);
    }
    loadImages();
  }, [])

  const openTrailerModal = (img) => {
    const imgId = img.split('2F')[1].split('?')[0]
    for (let i = 0; i < posterInfo.length; i++){
      let id = posterInfo[i]['id']
      if (imgId == id){
        setCurrentModalInfo(posterInfo[i])
      }
    }
    setIsTrailerModalOpen(true)
  }

  const closeTrailerModal = () => {
    setIsTrailerModalOpen(false)
  }

  const openCarouselModal = () => {
    setIsEditCarouselOpen(true)
  }

  const closeCarouselModal = () => {
    setIsEditCarouselOpen(false)
  }

  const openAuthModal = () => {
    setAuthModalOpen(true)
  }

  const closeAuthModal = () => {
    setAuthModalOpen(false)
  }

  const openPlacementModal = () => {
    setPlacementmodalOpen(true)
  }

  const closePlacementModal = () => {
    setPlacementmodalOpen(false)
  }

  const signOut = () => {
    setSignedIn(false)
    setUser(null)
  }

  const Authenticate = () => {
    closeAuthModal()
    signInWithEmailAndPassword(auth, authEmail, authPwd).then((userCredential) => {
      setUser(userCredential.user)
      setSignedIn(true)
    }).catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
    })
  }

  const TitleImage = () => {
    return (
      <div>
        <center><img src="title.jpg" alt="title image" style={{width: '100%'}}/></center>
      </div>
    )
  }

  const Carousel = () => {
    let picArr = []
    for (let i = 0; i < carouselImages.length; i++){
      picArr.push(<SwiperSlide key={carouselImages[i]}><img src={carouselImages[i]} style={{width: "80%", height: "60%"}}/></SwiperSlide>)
    }
    return (
      <div>
        <center>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{delay: 2500, disableOnInteraction: false}}
        >
        {picArr.map((img, key) => img)}
        </Swiper>
        </center>

      </div>
    )
  }

  const Footer = () => {
    return (
      <div>
        <small style={{color: 'white'}}>Copyright &copy; James R. Norman 2022 | <span onClick={openAuthModal}>Edit</span></small>
      </div>
    )
  }

  const placementNotification = () => {
    notification.open({
      message: "Success!",
      description: "Congradulations on the new placement!"
    })
  }

  const uploadCarouselImage = () => {
    const storageRef = ref(storage, '/carousel_images/' + carouselAdditionName)
    uploadBytes(storageRef, carouselImageUpload).then((snapshot) => {
      closeCarouselModal()
    })
  }

  const uploadPlacement = () => {
    const moviePath = '/posters/' + posterImages.length
    const storageRef = ref(storage, moviePath)
    uploadBytes(storageRef, moviePoster).then((snapshot) => {
      const data = {
        song: songName,
        start: startTime,
        end: endTime,
        link: trailerLink,
        id: posterImages.length
      }
      set(ref_realtime(database, 'posters/' + movieName), data).then(() => {
        placementNotification()
        closePlacementModal()
      })
    })
  }

  const PlacementsBanner = () => {
    return (
      <div className="p-3 container" style={{textAlign: 'center', margin: 'auto'}}>
        <Card>
          <Card.Body>
            <h1 style={{size: '2em'}}>PLACEMENTS</h1>
          </Card.Body>
        </Card>
      </div>
    )
  }

  const Placements = () => {
    posterImages.sort((a, b) => {
      const imgIdA = parseInt(a.split('2F')[1].split('?')[0])
      const imgIdB = parseInt(b.split('2F')[1].split('?')[0])
      return imgIdA - imgIdB
    })
    let posterHTML = []
    for (let i = posterImages.length-1; i >= 0; i--){
      posterHTML.push(<Col key={posterImages[i]} style={{paddingBottom: '1rem'}}><Card onClick={() => openTrailerModal(posterImages[i])} style={{width: '18rem'}}><Card.Img variant="top" src={posterImages[i]}/></Card></Col>)
    }
    return (
      <div className="container-fluid" style={{textAlign: 'center', display: 'inline-block', margin: '0 auto', padding: '1rem'}}>
        <center>
          <Row>
            {posterHTML.map(card => card)}
          </Row>
        </center>
      </div>
    )
  }

  const TrailerModal = () => {
    if (currentModalInfo == null){
      return ''
    }
    else {
      const isVimeo = currentModalInfo['link'].includes('vimeo');
      return (
        <div>
          <Modal show={isTrailerModalOpen} onHide={closeTrailerModal}>
            <Modal.Header closeButton>
              <Modal.Title>{currentModalInfo['name']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Featuring the track {currentModalInfo['song']} from {currentModalInfo['start']} to {currentModalInfo['end']}.</p>
              <ReactPlayer width='90%' url={currentModalInfo['link']}
                config={{
                  vimeo: {
                    playerOptions: {
                      controls: isVimeo, // Show controls for Vimeo videos
                    },
                  },
                }}
        
              />
            </Modal.Body>
          </Modal>
        </div>
      )
    }
  }

  const Page = () => {
    if (!signedIn){
      return (
        <div style={{background: 'black'}}>
          <TitleImage/>
          <Carousel/>
          <PlacementsBanner/>
          <Placements/>
          <TrailerModal/>
          <Footer/>
         </div>
      )
    }
    else {
      return (
        <div>
          <Container>
            <Row>
              <Col>
                <Card onClick={openPlacementModal} style={{width: '18rem'}}>
                  <Card.Title>Add Placement</Card.Title>
                </Card>
              </Col>
              <Col onClick={openCarouselModal}>
                <Card style={{width: '18rem'}}>
                  <Card.Title>Add Carousel Image</Card.Title>
                </Card>
              </Col>
              <Col onClick={signOut}>
                <Card style={{width: '18rem'}}>
                  <Card.Title>Sign Out</Card.Title>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
  }

  return (
    <div>
      <Page/>
      <Modal show={authModalOpen} onHide={closeAuthModal}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
       <Modal.Body>
         <Form onSubmit={Authenticate}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control type="email" placeholder="Enter Email" onChange={(e) => setAuthEmail(e.target.value)}/>
            </Form.Group>
          <Form.Group className="mb-3" controlId='formBasicPassword'>
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" onChange={(e) => setAuthPwd(e.target.value)}/>
          </Form.Group>
          <Button variant="primary" type="button" onClick={Authenticate}>Submit</Button>
         </Form>
        </Modal.Body>
       </Modal>
       <Modal show={isEditCarouselOpen} onHide={closeCarouselModal}>
         <Modal.Header closeButton>
           <Modal.Title>Add Carousel Image</Modal.Title>
         </Modal.Header>
        <Modal.Body>
          <Form onSubmit={uploadCarouselImage}>
            <Form.Group className='mb-3'>
              <Form.Label>Movie Name</Form.Label>
              <Form.Control placeholder="Movie Name" onChange={(e) => setCarouselAdditionName(e.target.value)}/>
            </Form.Group>
            <Form.Group controlId='formFile' className='mb-3'>
              <Form.Label>Select Image For Carousel</Form.Label>
              <Form.Control type='file' onChange={(e) => setCarouselImageUpload(e.target.files[0])}/>
            </Form.Group>
           <Button variant="primary" type="button" onClick={uploadCarouselImage}>Add Image</Button>
          </Form>
         </Modal.Body>
        </Modal>
        <Modal show={placementModalOpen} onHide={closePlacementModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Placement</Modal.Title>
          </Modal.Header>
         <Modal.Body>
           <Form onSubmit={uploadPlacement}>
             <Form.Group className='mb-3'>
               <Form.Label>Movie Name</Form.Label>
               <Form.Control placeholder="Movie Name" onChange={(e) => setMovieName(e.target.value)}/>
             </Form.Group>
             <Form.Group className='mb-3'>
               <Form.Label>Song Name</Form.Label>
               <Form.Control placeholder="Song Name" onChange={(e) => setSongName(e.target.value)}/>
             </Form.Group>
             <Form.Group className='mb-3'>
               <Form.Label>Trailer Link</Form.Label>
               <Form.Control placeholder="Trailer Link" onChange={(e) => setTrailerLink(e.target.value)}/>
             </Form.Group>
             <Form.Group className='mb-3'>
               <Form.Label>Start Time</Form.Label>
               <Form.Control placeholder="Start Time" onChange={(e) => setStartTime(e.target.value)}/>
             </Form.Group>
             <Form.Group className='mb-3'>
               <Form.Label>End Time</Form.Label>
               <Form.Control placeholder="End Time" onChange={(e) => setEndTime(e.target.value)}/>
             </Form.Group>
             <Form.Group controlId='formFile' className='mb-3'>
               <Form.Label>Upload Movie Poster</Form.Label>
               <Form.Control type='file' onChange={(e) => setMoviePoster(e.target.files[0])}/>
             </Form.Group>
            <Button variant="primary" type="button" onClick={uploadPlacement}>Add Placement</Button>
           </Form>
          </Modal.Body>
         </Modal>
    </div>
  )
}
