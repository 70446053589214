import logo from './logo.svg';
import './App.css';
import {JRNView} from './JRNView.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import { RegisterView } from './RegisterView';

function App() {
  return <JRNView/>
}

export default App;
